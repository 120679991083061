import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../Store/Actions/index";
import styled from "styled-components";

const StyledContainer = styled.div`
  position: fixed;
  background: #1f1f1f;
  width: 60%;
  height: 80px;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.7);
  z-index: 99999999;

  @media (max-width: 1024px) {
    width: 90%;
  }
`;

const StyledContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AlertBody = styled.div`
  font-family: "Jura", sans-serif;
  font-size: 1rem;
  display: flex;
  width: 80%;
  color: #fff;
  margin-left: 20px;
`;

const AlertClose = styled.button`
  border: none;
  background-color: transparent;
  font-family: "Jura", sans-serif;
  font-size: 1rem;
  color: #fff;
  margin-right: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;

  :hover {
    text-shadow: 0px 2px 0px #ffffff70;
  }
`;

class Notification extends Component {
  render() {
    return (
      <StyledContainer>
        <StyledContent>
          {this.props.cardError ? (
            <>
              <AlertBody>{this.props.cardError}</AlertBody>
              <AlertClose onClick={() => this.props.onCleanCardError()}>
                X
              </AlertClose>
            </>
          ) : null}
          {this.props.authError ? (
            <>
              <AlertBody>{this.props.authError}</AlertBody>
              <AlertClose onClick={() => this.props.onCleanAuthError()}>
                X
              </AlertClose>
            </>
          ) : null}
        </StyledContent>
      </StyledContainer>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cardError: state.cards.error,
    authError: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCleanCardError: () => dispatch(action.cleanCardError()),
    onCleanAuthError: () => dispatch(action.cleanAuthError()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
