import React, { Component } from "react";
import { connect } from "react-redux";
import ReactMarkdown from "react-markdown";
import * as action from "../Store/Actions/index";

import Content from "../Components/contentCardWrapper";
import Title from "../Components/textTitle";
import Text from "../Components/textLabel";
import Spinner from "../Components/spinner";
import styled from "styled-components";
import Colors from "../Utils/StyleHelper";

const Container = styled.div`
  position: relative;
  text-align: center;
  height: 200px;
  width: 200px;
  border: 2px ${Colors.colors.second} solid;
  border-radius: 3px;
  margin: auto;
`;

const ImgCard = styled.img`
  display: block;
  width: 100%;
`;

class PublicView extends Component {
  componentDidMount() {
    this.props.onGetCard(this.props.match.params.uuid);
  }

  render() {
    let Screen = null;
    if (this.props.loading) {
      Screen = <Spinner />;
    }

    return (
      <Content small listation view>
        {Screen}
        <Title listation view>
          {this.props.card.title}
        </Title>
        <Container>
          <ImgCard src={this.props.card.file_url} alt="" />
        </Container>
        <Content>
          <Text left>
            <ReactMarkdown>{this.props.card.body}</ReactMarkdown>
          </Text>
        </Content>
      </Content>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    card: state.cards.card,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCard: (id) => dispatch(action.getScannedCard(id)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PublicView);
