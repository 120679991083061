import styled from "styled-components";

export default styled.hr`
  width: 100%;
  margin: 0;
  border-bottom: 3px solid #51cec5;
  border-top: 0;
  border-left: 0;
  border-right: 0;
`;
