import styled from "styled-components";

export default styled.div`
  width: ${(props) => (props.width ? props.width : "auto")};
  display: flex;
  font-family: "Teko", sans-serif;
  color: ${(props) => (props.primary ? "white" : "black")};
  font-size: 2rem;
  line-height: 1.9rem;
  text-align: center;
  align-items: center;
  white-space: nowrap;
  margin: 0px 10px;

  ${(props) =>
    props.center
      ? `justify-content: center;
    width: 100%;`
      : `justify-content: flex-start;`}

  @media (max-width: 1024px) {
    width: 25%;
  }
`;
