import React from "react";
import styled from "styled-components";
import StyleHl from "../Utils/StyleHelper";

const Content = styled.div`
    width: 900px;
    display: flex;
    margin: auto;
    padding: 20px 0;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @media (max-width: 1024px) {
        width: 100%;
    }    

`;

const TitleOne = styled.p`
    display: flex;
    font-family: "Teko", sans-serif;
    line-height: 1.2rem;
    margin: 0;
    font-size: 3rem;
    top: 16px;
    left: -121px;
    position: relative;
    opacity: 0.5;
    

    @media (max-width: 1024px) {
        font-size: 2.5rem;
        top: 57px;
        left: -100px;
    }    
`;

const TitleTwo = styled.p`
    display: flex;
    font-family: "Teko", sans-serif;
    line-height: 1.2rem;
    margin: 0;
    font-size: 5em;
    color: ${StyleHl.colors.first};
    opacity: 0.6;
    z-index:9999;
    filter: drop-shadow(-4px 4px 0px #00000020);
    
    @media (max-width: 1024px) {
        font-size: 4rem;
        white-space: nowrap;
    }    
`;

const TitleThree = styled.p`
    display: flex;
    font-family: "Teko", sans-serif;
    line-height: 1.2rem;
    margin: 0;
    font-size: 3rem;
    top: -23px;
    left: 135px;
    position: relative;
    opacity: 0.5;
    

    @media (max-width: 1024px) {
        font-size: 2.5rem;
        top: -65px;
        left: 105px;
    }    
`;

const HomeSubtitle = () => (
    <Content>
        <TitleOne>How</TitleOne>
        <TitleTwo>SQR HUB</TitleTwo>
        <TitleThree>works</TitleThree>
    </Content>
);

export default HomeSubtitle;