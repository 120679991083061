import * as actionTypes from "../Actions/actionTypes";

const initialState = {
    changedData: {},
    dirtyChanges: false,
    invalid: false,
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_MODIFICATIONS: return { ...state, changedData: action.payload, dirtyChanges: true };
        case actionTypes.CLEAN_MODIFICATIONS: return initialState;
        default:
            return state;
    }
};

export default reducer;