import * as actionTypes from "../Actions/actionTypes";

const initialState = {
  cardsList: [],
  card: {},
  filteredCards: [],
  redirect: false,
  error: false,
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_USER_CARDS:
      return { ...state, cardsList: action.payload };
    case actionTypes.FETCH_CARD_FAILED:
      return { ...state, error: true, redirect: true };
    case actionTypes.SET_FILTERED_CARDS:
      return { ...state, filteredCards: action.payload };

    case actionTypes.POST_NEW_CARD:
      return { ...state, card: action.payload };
    case actionTypes.LOAD_CARD:
      return { ...state, card: action.payload };
    case actionTypes.EDIT_CARD:
      return { ...state, card: action.payload };
    case actionTypes.CLEAN_CARD:
      return { ...state, card: initialState.card };
    case actionTypes.CLEAN_CARD_ERROR:
      return { ...state, error: false };
    case actionTypes.CARD_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.CARD_REQUEST_END:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
