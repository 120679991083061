import styled from "styled-components";

import StyleHl from "../Utils/StyleHelper";

export default styled.a`
  font-family: "Teko", sans-serif;
  background: ${props => props.filled ? StyleHl.colors.second : "white"};
  color: ${props => props.filled ? "white" : StyleHl.colors.second};
  width: 40px;
  height: 40px;
  border: 2px solid ${StyleHl.colors.second};
  border-radius: 3px;
  text-decoration: none;
  margin: ${props => props.right ? "none" : "auto"};
  text-align: center;
  line-height: 45px;
  
  :hover {
    background:  ${props => props.filled ? StyleHl.colors.hover.violet : "white"};
    color: ${props => props.filled ? "white" : StyleHl.colors.hover.violet};
    border: 2px solid ${StyleHl.colors.hover.violet};
    cursor: pointer;
  }

  :hover svg{
    fill: #BE7DD8;
  }

  @media (max-width: 1024px) {
    margin: none;
  }
  
`;