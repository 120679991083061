import React, { useRef } from 'react';
import ReactToPrint, { useReactToPrint } from 'react-to-print';

import PrinterPage from '../Components/printerPage';

const Printer = () => {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <div>
      <PrinterPage ref={componentRef} />
      <button onClick={handlePrint}>Print this out!</button>
    </div>
  );
};

export default Printer;
