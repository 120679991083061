import styled from "styled-components";
import img from "../Img/back-view.svg";

export default styled.div`
  width: ${(props) => (props.small ? "40%" : "80%")};
  background-size: 100%;
  display: flex;
  flex-direction: ${(props) => (props.row ? "row" : "column")};
  justify-content: ${(props) => (props.left ? "flex-start" : "center")};
  align-items: ${(props) => (props.left ? "flex-start" : "center")};
  margin: 20px 0;

  ${(props) =>
    props.view
      ? `box-shadow: 4px 4px 10px #bbbbbb;
        background-image:url("${img}");
        border-radius: 5px;
        padding: 20px  0;`
      : `box-shadow: none;
        background-image: none;
        border-radius:none;
        padding: 0;`}

  @media (max-width: 1024px) {
    width: 95%;
    align-items: ${(props) => (props.left ? "flex-start" : "center")};
  }
`;
