import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../Store/Actions/index";

import Content from "../../Components/contentCardWrapper";
import Label from "../../Components/textLabel";
import Input from "../../Components/input";
import Helper from "../../Components/textHelper";
import Submit from "../../Components/inputBtn";
import Spinner from "../../Components/spinner";

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin: 20px 0;
`;

class ForgotPassword extends Component {
  state = {
    email: null,
    password: null,
    passwordConf: null,

    validations: {
      email: {
        valid: true,
        msg: "Insert valid email.",
      },
      password: {
        valid: true,
        msg: "Minimun length 6.",
      },
      passwordConf: {
        valid: true,
        msg: "Password doesn't match.",
      },
    },
  };

  componentDidMount() {}

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value }, () => this.checkValidity(name));
  };

  updateValidations = (value) => {
    const newValidations = {
      ...this.state.validations,
      value,
    };
    this.setState({ validations: newValidations });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const data = { email: this.state.email };
    this.props.onRequestResetPassword(data);
  };

  checkValidity = (name) => {
    const validation = this.state.validations[name];
    const valueToCheck = this.state[name];

    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    validation.valid = pattern.test(valueToCheck);
    this.updateValidations(validation);
  };

  isFormValid = () => {
    return this.state.validations.email.valid;
  };

  render() {
    let Screen = null;
    if (this.props.loading) {
      Screen = <Spinner />;
    }

    return (
      <Content small>
        {Screen}
        <Form onSubmit={this.handleSubmit}>
          <Helper center>TO RESET PASSWORD PLEASE ENTER YOUR EMAIL</Helper>

          <Label>E-MAIL</Label>
          <Input
            elementType="input"
            type="email"
            name="email"
            content={this.state.email}
            changes={this.handleChange}
            validation={this.state.validations.email}
          />
          <Content listation>
            <Submit
              type="submit"
              disabled={!this.isFormValid()}
              value="SEND"
            ></Submit>
          </Content>
        </Form>
      </Content>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onRequestResetPassword: (data) => dispatch(action.requestResetPassword(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
);
