import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
  background-color: white;
  height: 30px;
  width: 30px;
  margin: auto;
  margin-top: 5px;
`;

const SvgImg = styled.svg`
  fill: #a231cf;
`;

export default () => (
  <Wrap title="Download">
    <SvgImg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 132 129.9">
      <polygon points="66 91.4 99.6 57.8 80.4 57.8 80.4 0.1 51.6 0.1 51.6 57.8 32.4 57.8 " />
      <polygon points="109.2 81.8 109.2 110.6 22.7 110.6 22.7 81.8 3.5 81.8 3.5 110.6 3.5 129.8 3.5 129.8 128.4 129.8 128.4 129.8 128.4 110.6 128.4 81.8 " />
    </SvgImg>
  </Wrap>
);
