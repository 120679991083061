import { combineReducers } from "redux";

import cardsReducer from "./Cards";
import authReducer from "./Auth";
import modifications from "./mod";
import verifications from "./verifications";

const rootReducer = combineReducers({
  cards: cardsReducer,
  auth: authReducer,
  mod: modifications,
  ver: verifications,
});

export default rootReducer;
