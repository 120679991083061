import * as actionTypes from "./actionTypes";
import history from "../../Containers/helpers/history";

export const filterCards = (lookup) => {
  return (dispatch) => {
    dispatch(cardRequestStart());
    fetch(`${process.env.REACT_APP_API_URL}/v1/cards/search?lookup=${lookup}`, {
      method: "get",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Credentials: "same-origin",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((cards) => {
              dispatch(setFilteredCards(cards));
            })
            .catch((error) => {
              dispatch(fetchFailed(error));
            });
        } else if (response.status === 401) {
          dispatch(
            fetchFailed("There was a problem, please sign in and try again")
          );
          history.push(`/log_in`);
        } else if (response.status === 404) {
          history.push(`/404`);
        }
      })
      .catch((error) => {
        dispatch(fetchFailed(error));
      })
      .finally(() => {
        dispatch(cardRequestEnd());
      });
  };
};

export const getUserCards = () => {
  return (dispatch) => {
    dispatch(cardRequestStart());
    fetch(`${process.env.REACT_APP_API_URL}/v1/cards`, {
      method: "get",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Credentials: "same-origin",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((cards) => {
              dispatch(setUsersCards(cards));
              dispatch(setFilteredCards(cards));
            })
            .catch((error) => {
              dispatch(fetchFailed(error));
            });
        } else if (response.status === 401) {
          dispatch(
            fetchFailed("There was a problem, please sign in and try again")
          );
          history.push(`/log_in`);
        } else if (response.status === 404) {
          history.push(`/404`);
        }
      })
      .catch((error) => {
        dispatch(fetchFailed(error));
      })
      .finally(() => {
        dispatch(cardRequestEnd());
      });
  };
};

const setUsersCards = (data) => {
  return {
    type: actionTypes.GET_USER_CARDS,
    payload: data,
  };
};

export const setFilteredCards = (data) => {
  return {
    type: actionTypes.SET_FILTERED_CARDS,
    payload: data,
  };
};

const fetchFailed = (error) => {
  return {
    type: actionTypes.FETCH_CARD_FAILED,
    payload: error,
  };
};

export const postNewCard = (card) => {
  return (dispatch) => {
    dispatch(cardRequestStart());
    fetch(`${process.env.REACT_APP_API_URL}/v1/cards`, {
      method: "post",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Credentials: "same-origin",
      },
      body: JSON.stringify(card),
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((newCard) => {
              dispatch(setNewCard(newCard));
              history.push(`/view/${newCard.uuid}`);
            })
            .catch((error) => {
              dispatch(fetchFailed(error));
            });
        } else if (response.status === 401) {
          dispatch(
            fetchFailed("There was a problem, please sign in and try again")
          );
          history.push(`/log_in`);
        } else if (response.status === 404) {
          history.push(`/404`);
        }
      })
      .catch((error) => {
        dispatch(fetchFailed(error));
      })
      .finally(() => {
        dispatch(cardRequestEnd());
      });
  };
};

const setNewCard = (data) => {
  return {
    type: actionTypes.POST_NEW_CARD,
    payload: data,
  };
};

export const getCard = (uuid) => {
  return (dispatch) => {
    dispatch(cardRequestStart());
    fetch(`${process.env.REACT_APP_API_URL}/v1/cards/${uuid}`, {
      method: "get",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Credentials: "same-origin",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((card) => {
              dispatch(loadCard(card));
            })
            .catch((error) => {
              dispatch(fetchFailed(error));
            });
        } else if (response.status === 404) {
          history.push(`/404`);
        } else if (response.status === 401) {
          dispatch(
            fetchFailed("There was a problem, please sign in and try again")
          );
          history.push(`/log_in`);
        }
      })
      .catch((error) => {
        dispatch(fetchFailed(error));
      })
      .finally(() => {
        dispatch(cardRequestEnd());
      });
  };
};

const loadCard = (data) => {
  return {
    type: actionTypes.LOAD_CARD,
    payload: data,
  };
};

export const editCard = (uuid, data) => {
  return (dispatch) => {
    dispatch(cardRequestStart());
    fetch(`${process.env.REACT_APP_API_URL}/v1/cards/${uuid}`, {
      method: "put",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Credentials: "same-origin",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((card) => {
              dispatch(loadCard(card));
              history.push(`/view/${uuid}`);
            })
            .catch((error) => {
              dispatch(fetchFailed(error));
            });
        } else if (response.status === 401) {
          dispatch(
            fetchFailed("There was a problem, please sign in and try again")
          );
          history.push(`/log_in`);
        } else if (response.status === 404) {
          history.push(`/404`);
        }
      })
      .catch((error) => {
        dispatch(fetchFailed(error));
      })
      .finally(() => {
        dispatch(cardRequestEnd());
      });
  };
};

export const getScannedCard = (uuid) => {
  return (dispatch) => {
    dispatch(cardRequestStart());
    fetch(`${process.env.REACT_APP_API_URL}/${uuid}`, {
      method: "get",
      mode: "cors",
      headers: {
        "Cache-Control": "no-cache",
        Accept: "application/json",
        "Content-Type": "application/json",
        Credentials: "same-origin",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((card) => {
              dispatch(loadCard(card));
              history.push(`/${uuid}`);
            })
            .catch((error) => {
              dispatch(fetchFailed(error));
            });
        } else if (response.status === 404) {
          history.push(`/404`);
        } else if (response.status === 401) {
          dispatch(
            fetchFailed("There was a problem, please sign in and try again")
          );
          history.push(`/log_in`);
        }
      })
      .catch((error) => {
        dispatch(fetchFailed(error));
      })
      .finally(() => {
        dispatch(cardRequestEnd());
      });
  };
};

export const deleteCard = (uuid) => {
  return (dispatch) => {
    dispatch(cardRequestStart());
    fetch(`${process.env.REACT_APP_API_URL}/v1/cards/${uuid}`, {
      method: "delete",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Credentials: "same-origin",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((res) => {
              dispatch(removeCard(res));
            })
            .then((res) => {
              history.push("/my_cards");
            })
            .catch((error) => {
              dispatch(fetchFailed(error));
            });
        } else if (response.status === 404) {
          history.push(`/404`);
        } else if (response.status === 401) {
          dispatch(
            fetchFailed("There was a problem, please sign in and try again")
          );
          history.push(`/log_in`);
        }
      })
      .catch((error) => {
        dispatch(fetchFailed(error));
      })
      .finally(() => {
        dispatch(cardRequestEnd());
      });
  };
};

const removeCard = (data) => {
  return {
    type: actionTypes.REMOVE_CARD,
    payload: data,
  };
};

export const cleanCard = () => {
  return {
    type: actionTypes.CLEAN_CARD,
  };
};

export const cardRequestStart = () => {
  return {
    type: actionTypes.CARD_REQUEST_START,
  };
};

export const cardRequestEnd = () => {
  return {
    type: actionTypes.CARD_REQUEST_END,
  };
};

export const cleanCardError = () => {
  return {
    type: actionTypes.CLEAN_CARD_ERROR,
  };
};
