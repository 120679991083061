import React from "react";
import styled from "styled-components";
import StyleHl from "../Utils/StyleHelper";

const Wrap = styled.div`
  width: 50px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${StyleHl.colors.first};
  border-radius: 3px;
  margin: auto;
  background: ${(props) => (props.filled ? StyleHl.colors.first : "white")};
  color: ${(props) => (props.filled ? "white" : StyleHl.colors.first)};

  :focus {
    outline: none;
    box-shadow: 0px 0px 5px #be7dd8;
  }

  :hover {
    background: ${(props) =>
      props.filled ? StyleHl.colors.hover.violet : "white"};
    color: ${(props) => (props.filled ? "white" : StyleHl.colors.hover.violet)};
    border: 2px solid ${StyleHl.colors.hover.violet};
    cursor: pointer;
  }

  :hover svg {
    fill: #be7dd8;
  }
`;

const SvgImg = styled.svg`
  height: 30px;
  margin: 5px;
  fill: #a231cf;
`;

export default ({ filled, onClick }) => (
  <Wrap filled={filled} onClick={onClick} title="Remove Card">
    <SvgImg
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <g>
        <path
          class="st0"
          d="M15.2,3.8V0.9H4.8v2.9H1.4v2.1h17.2V3.8H15.2z M6.6,2.5h6.8v1.3H6.6V2.5z"
        />
        <path
          class="st0"
          d="M2.7,6.7v12.4h14.7V6.7H2.7z M6.6,17.3H4.9V8.2h1.7V17.3z M10.9,17.3H9.2V8.2h1.7V17.3z M15.1,17.3h-1.7V8.2
		h1.7V17.3z"
        />
      </g>
    </SvgImg>
  </Wrap>
);
