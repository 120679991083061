import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
import { Router, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import history from "./Containers/helpers/history";

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import rootReducer from "./Store/Reducers";

import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
//TODO: Turn off reCaptcha for automated tests
import { GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const Root = () => {
    const gtmParams = {
        id: 'GTM-5BZX6DB'
    }

    return <Provider store={store}>
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY}
            useRecaptchaNet="true"
        >
            <GTMProvider state={gtmParams}>
                <Router history={history}>
                    <App />
                </Router>
            </GTMProvider>
        </GoogleReCaptchaProvider>
    </Provider>
};

ReactDOM.render(<Root/>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
