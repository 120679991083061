import * as actionTypes from "./actionTypes";

export const setChanges = (data) => {
    return {
        type: actionTypes.SET_MODIFICATIONS,
        payload: data,
    };
};

export const cleanChanges = () => {
    return {
        type: actionTypes.CLEAN_MODIFICATIONS,
    };
};