import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const TextLink = styled(Link)`
  font-family: "Jura", sans-serif;
  padding: 0px 15px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  font-size: ${(props) => (props.small ? "0.9rem" : "1.3rem")};
  cursor: ${(props) => (props.isthere === true ? "default" : "pointer")};
  ${(props) => (props.home ? `color: white;` : `color: #a231cf;`)};

  :hover {
    ${(props) =>
      props.home
        ? `outline: none; text-shadow: 0px 3px 2px #ffffff70;`
        : `outline: none; text-shadow: 0px 2px 1px #BE7DD880;`};
  }

  :focus {
    ${(props) =>
      props.home
        ? `outline: none; box-shadow: 0px 0px 5px #585858;`
        : `outline: none; box-shadow: 0px 0px 5px #be7dd8;`};
  }

  @media (max-width: 1024px) {
    font-size: 1.4em;
    height: 40px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
`;

const LinkButton = (props) => <TextLink {...props} />;

export default LinkButton;
