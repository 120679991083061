import styled from "styled-components";
import StyleHl from "../Utils/StyleHelper";
import ImgWhite from "../Img/back-white.svg";
import ImgCyan from "../Img/back-cyan.svg";

export default styled.div`
    width: 100%;
    background-image: url("${ImgCyan}");
    height: 220px;
    position: relative;

    ${props => props.white ?
        `background-image: url("${ImgWhite}");
        background-color: ${StyleHl.colors.second};
        padding-bottom: 10px`
        : ``}

    ${props => props.bottom ?
        `bottom: 1px;
        height: 190px;`
        : ``}
        
    ${props => props.top ?
        `top: 1px;`
        : ``}

    ${props => props.invert ?
        `-moz-transform : scaleY(-1);
        -o-transform: scaleY(-1);
        -webkit-transform: scaleY(-1);
        transform: scaleY(-1);
        filter: FlipV;
        -ms-filter: "FlipV";`
        : ``}

    ${props => props.mirror ?
        `-moz-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
        filter: FlipH;
        -ms-filter: "FlipH";
        height: 190px;`
        : ``}


    @media (max-width: 1024px) {
        height: 115px;

        ${props => props.white ?
        `background-image: url("${ImgWhite}");
        height: 150px;
        padding: 0;
        background-color: ${StyleHl.colors.second};
        `
        : ``}

        ${props => props.bottom ?
        `height: 121px;`
        : ``}

        ${props => props.mirror ?
        `height: 121px;`
        : ``}

    }  
`;

