import styled from "styled-components";
import img from "../Img/Logo/logo-white.svg";

export default styled.div`
    background-image: url("${img}");
    background-repeat: no-repeat;
    background-position: center;
    height: 90px;
    width: 90px;

    @media (max-width: 1024px) {
        margin: 5px;
        height: 40px;
        width: 65px;
    }    
`;
