import React from "react";
import styled from "styled-components";
import StyleHl from "../Utils/StyleHelper";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
`;

const StyledInput = styled.input`
  font-family: "Jura", sans-serif;
  padding: 0px 5px;
  color: grey;
  width: 60%;
  height: ${(props) => (props.big ? "6em" : "2em")};
  font-size: 1rem;
  border: 2px #51cec5 solid;
  border-radius: 3px;
  margin-bottom: ${(props) => (props.formType ? "1px" : "20px")};

  ::placeholder {
    font-style: italic;
  }

  :focus {
    outline: none;
    border-color: #be7dd8;
    box-shadow: 0px 0px 3px #be7dd8;
  }

  @media (max-width: 1024px) {
    width: 90%;
  }
`;

const StyledTextarea = styled.textarea`
  font-family: "Jura", sans-serif;
  color: grey;
  width: 60%;
  height: 6em;
  font-size: 1em;
  border: 2px #51cec5 solid;
  border-radius: 3px;
  margin-bottom: 5px;

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
  }

  ::placeholder {
    font-style: italic;
  }

  :focus {
    outline: none;
    border-color: #be7dd8;
    box-shadow: 0px 0px 3px #be7dd8;
  }

  @media (max-width: 1024px) {
    width: 90%;
  }
`;

const StyledError = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  align-content: center;
  font-family: "Jura", sans-serif;
  color: red;
  font-size: 0.8em;
`;

const Input = ({
  content,
  required,
  validation,
  elementType,
  changes,
  formType,
  ...props
}) => {
  let inputElement = null;
  let validationError = null;

  if (validation && !validation.valid) {
    validationError = <StyledError>{validation.msg}</StyledError>;
  }

  switch (elementType) {
    case "input":
      inputElement = (
        <StyledInput
          {...props}
          formType={formType}
          required={required}
          value={content === null || content === undefined ? "" : content}
          onChange={changes}
        />
      );
      break;
    case "textarea":
      inputElement = (
        <StyledTextarea
          {...props}
          required={required}
          value={content === null || content === undefined ? "" : content}
          onChange={changes}
        />
      );
      break;
    /* case ("inputDesk"):
            inputElement = <StyledInputDesk
                {...props}
                value={props.content === null || props.content === undefined ? "" : props.content}
                onChange={props.changes} />;
            break;
        case ("textareaDesk"):
            inputElement = <StyledTextareaDesk
                {...props}
                value={props.content === null || props.content === undefined ? "" : props.content}
                onChange={props.changes} />;
            break; */
    default:
      inputElement = (
        <StyledInput
          {...props}
          formType={formType}
          required={required}
          value={content === null || content === undefined ? "" : content}
          onChange={changes}
        />
      );
  }

  return (
    <Container>
      {inputElement}
      {validationError}
    </Container>
  );
};

export default Input;
