import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from 'react-router-dom';

import img from "../Img/back-view.svg";

const Wrap = styled.div`
    width: 70%;
    display: flex;
    flex-direction:column;
    align-items: center;
    margin: 50px auto;

    @media (max-width: 1024px) {
    width: 100%;
    margin: 25px 15px;
    }
`;

const Content = styled.div`
    width: 80%;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 4px 4px 10px #bbbbbb;
    background-image: url("${img}");
    border-radius: 5px;
    height: 80vmin ;
        


    @media (max-width: 1024px) {
       
    }

`;

const Msg = styled.p`
font-family: 'Jura', sans-serif;
    text-transform: uppercase;
    font-size: 2rem;
    margin: 0;
    color: #cccccc;

    @media (max-width: 1024px) {
    
    }
`;

const Error = styled.p`
    font-family: "Teko", sans-serif;
    font-size: 10rem;
    margin: 0;
    color: #cccccc;

    @media (max-width: 1024px) {
   
    }
`;


class Screen404 extends Component {

    render() {

        return (
            <Wrap>
                <Content view="true" >
                    <Msg>Sorry, something went Wrong!!</Msg>
                    <Error>404</Error>
                </Content>
            </Wrap >
        );
    };
};




export default withRouter(Screen404);