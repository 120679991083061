import React from "react";
import styled from "styled-components";

const Wrap = styled.div`
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
`;

const Label = styled.label`
  margin: 0 5px;
 font-family: 'Jura', sans-serif;
  font-size: 16px;
  padding-right: 5px;
  text-align: center;
  line-height: 1.2rem;
  white-space: nowrap;
`;

const CheckBoxWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 42px;
  height: 26px; 
  background: #bebebe;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    margin: 3px;
    background: #ffffff;
    box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.2);
    transition: 0.2s;
  }
`;
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: 42px;
  height: 26px;
  margin: 0px;
  &:checked + ${CheckBoxLabel} {
    background: #51cec5;
    &::after {
      display: block;
      width: 18px;
      height: 18px;
      margin-left: 21px;
      transition: 0.2s;
    }
  }
`;

const SwitchTxt = ({ textLabel, onClick }) => {
  return (
    <Wrap>
      <Label>{textLabel}</Label>
      <CheckBoxWrapper>
        <CheckBox
          id="checkbox"
          type="checkbox"
          onChange={onClick} />
        <CheckBoxLabel htmlFor="checkbox" />
      </CheckBoxWrapper>
    </Wrap>
  );
};
export default SwitchTxt;