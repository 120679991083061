import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../Store/Actions/index";
import StyleHl from "../Utils/StyleHelper";
import styled from "styled-components";

import Content from "../Components/contentCardWrapper";
import Label from "../Components/textHelper";
import Down from "../Components/download";
import TextLink from "../Components/textLink";
import Print from "../Components/print";
import StringToDownload from "../Utils/StringToDownload";
import printSvg from "../Utils/PrintSvg";

const SvgWrap = styled.div`
  height: 200px;
  width: 200px;
`;

const BtnRow = styled.div`
  margin: 40px 0;
  display: flex;
  width: 70%;
  padding: 0;
  justify-content: space-evenly;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const StyledBtn = styled.a`
  font-family: "Teko", sans-serif;
  background: ${(props) => (props.filled ? StyleHl.colors.first : "white")};
  color: ${(props) => (props.filled ? "white" : StyleHl.colors.first)};
  width: 40px;
  height: 40px;
  border: 2px solid ${StyleHl.colors.first};
  border-radius: 3px;
  text-decoration: none;
  margin: ${(props) => (props.right ? "none" : "auto")};
  text-align: center;
  line-height: 45px;

  :hover {
    background: ${(props) =>
      props.filled ? StyleHl.colors.hover.violet : "white"};
    color: ${(props) => (props.filled ? "white" : StyleHl.colors.hover.violet)};
    border: 2px solid ${StyleHl.colors.hover.violet};
    cursor: pointer;
  }

  :hover svg {
    fill: #be7dd8;
  }

  @media (max-width: 1024px) {
    margin: none;
  }
`;

class PrintCard extends Component {
  componentDidMount() {
    this.props.onGetCard(this.props.match.params.uuid);
  }

  render() {
    return (
      <Content small>
        <Content id="svg-container">
          <SvgWrap dangerouslySetInnerHTML={{ __html: this.props.cardQr }} />
        </Content>
        {/* <Content row>
          <StyledBtn right onClick={() => printSvg(this.props.cardQr)}>
            <Print />
          </StyledBtn>
          <Label width="60px">PRINT</Label>
        </Content> */}

        <Content row>
          <StyledBtn
            right
            onClick={() =>
              StringToDownload(`qr_${this.props.card}.svg`, this.props.cardQr)
            }
          >
            <Down />
          </StyledBtn>
          <Label width="60px">SVG</Label>
        </Content>

        <Content row>
          <StyledBtn
            right
            onClick={() =>
              StringToDownload(`qr_${this.props.card}.png`, this.props.cardQr)
            }
          >
            <Down />
          </StyledBtn>
          <Label width="60px">PNG</Label>
        </Content>

        <BtnRow>
          <TextLink to={`/view/${this.props.match.params.uuid}`}>BACK</TextLink>
          <TextLink filled="true" to={`/my_cards`}>
            HOME
          </TextLink>
        </BtnRow>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cardQr: state.cards.card.qr,
    card: state.cards.card.name,
    isAuth: state.auth.isAuth,
    user: state.auth.user,
    userEmail: state.auth.userEmail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCard: (uuid) => dispatch(action.getCard(uuid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PrintCard);
