import React from "react";
import history from "../helpers/history";

import styled, { keyframes } from "styled-components";
import ThirdBtn from "../../Components/thirdBtn";
import StyleHl from "../../Utils/StyleHelper";

const floatText = keyframes`
0% {
    transform: translateX(0%);
  }
100% {
  transform: translateX(-150px);
}
`;

const StyledWrap = styled.div`
  font-family: "Teko", sans-serif;
  color: black;
  width: 100%;
  height: 45px;
  font-size: 1.5em;
  text-align: left;
  padding: 15px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  justify-content: space-between;
  border-bottom: 1px solid ${StyleHl.colors.second};

  :focus {
    background-color: ${StyleHl.colors.hover.cyantwo};
    border-bottom: 1px solid ${StyleHl.colors.hover.cyantwo};
    outline: none;
  }

  :hover {
    background-color: ${StyleHl.colors.hover.cyantwo};
    border-bottom: 1px solid ${StyleHl.colors.hover.cyantwo};
  }

  @media (max-width: 600px) {
    width: 90%;
  }
`;

const Title = styled.div`
  width: 70%;
  font-family: "Jura", sans-serif;
  font-size: 20px;
  padding: 0;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  line-height: 45px;
  cursor: pointer;

  :hover {
    text-shadow: 0px 2px 0px ${StyleHl.colors.hover.cyan};
  }
`;

const Card = ({ uuid, cardName }) => (
  <StyledWrap>
    <Title onClick={() => history.push(`/view/${uuid}`)} title={cardName}>
      {cardName}
    </Title>
    <ThirdBtn right onClick={() => history.push(`/edit/${uuid}`)}>
      EDIT
    </ThirdBtn>
  </StyledWrap>
);

export default Card;
