export const GET_USER_CARDS = "GET_USER_CARDS";
export const FETCH_CARD_FAILED = "FETCH_CARD_FAILED";

export const POST_NEW_CARD = "POST_NEW_CARD";
export const REMOVE_CARD = "REMOVE_CARD";
export const LOAD_CARD = "LOAD_CARD";
export const EDIT_CARD = "EDIT_CARD";
export const CLEAN_CARD = "CLEAN_CARD";
export const SET_FILTERED_CARDS = "SET_FILTERED_CARDS";

export const IS_AUTH = "IS_AUTH";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const GET_USER = "GET_USER";
export const DELETE_USER = "DELETE_USER";
export const SET_NEW_DATA = "SET_NEW_DATA";

export const SET_REDIRECT = "SET_REDIRECT";

export const SET_MODIFICATIONS = "SET_MODIFICATIONS";
export const CLEAN_MODIFICATIONS = "CLEAN_MODIFICATIONS";

export const CARD_REQUEST_START = "CARD_REQUEST_START";
export const CARD_REQUEST_END = "CARD_REQUEST_END";

export const AUTH_REQUEST_START = "AUTH_REQUEST_START";
export const AUTH_REQUEST_END = "AUTH_REQUEST_END";

export const CLEAN_CARD_ERROR = "CLEAN_CARD_ERROR";

export const CLEAN_AUTH_ERROR = "CLEAN_AUTH_ERROR";

export const VERIFICATION_FAIL = "VERIFICATION_FAIL";
export const VERIFICATION_SUCCESS = "VERIFICATION_SUCCESS";
export const VERIFICATION_START = "VERIFICATION_START";
export const VERIFICATION_END = "VERIFICATION_END";
