import styled from "styled-components";
import StyleHl from "../Utils/StyleHelper";

export default styled.div`
    background-color: ${props => props.primary ? StyleHl.colors.second : "white"};
    color: ${props => props.primary ? "white" : "black"};
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;    
`;