import * as actionTypes from "../Actions/actionTypes";

const initialState = {
  user: null,
  isAuth: false,
  error: false,
  loading: false,
  msg: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.IS_AUTH:
      return { ...state, user: action.payload, isAuth: true, msg: "SignUp OK" };
    case actionTypes.AUTH_FAIL:
      return { ...state, error: action.payload };
    case actionTypes.AUTH_SUCCESS:
      return { ...state, user: action.payload, isAuth: true, msg: "SignIn OK" };

    case actionTypes.AUTH_REQUEST_START:
      return { ...state, loading: true };
    case actionTypes.AUTH_REQUEST_END:
      return { ...state, loading: false };

    case actionTypes.CLEAN_AUTH_ERROR:
      return { ...state, error: false };
    case actionTypes.DELETE_USER:
      return { initialState };

    default:
      return state;
  }
};

export default reducer;
