import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../Store/Actions/index";
import styled from "styled-components";
import StyleHl from "../Utils/StyleHelper";

import Spinner from "../Components/spinner";
import Button from "../Components/primaryBtn";
import Title from "../Components/textTitle";

const Wrapper = styled.div`
  width: 80%;
  display: flex;
  font-family: "Jura", sans-serif;
  display: block;
  padding-bottom: 120px;
  text-align: center;

  @media (max-width: 1024px) {
    width: 95%;
    align-items: ${(props) => (props.row ? "center" : "flex-start")};
  }
`;

const BtnWrap = styled.div`
  display: flex;
  justify-content: center;
  margin: 15px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

class VerificationMail extends Component {
  componentDidMount() {
    this.props.onVerificationMail(this.props.match.params.token);
  }

  render() {
    return (
      <Wrapper>
        {this.props.loading ? (
          <>
            <Spinner />
            <Title view>Verifying Email, please wait</Title>
          </>
        ) : (
          <>
            <Title view>Done!</Title>
            <Title view> Thank You for verifying your email.</Title>
          </>
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.ver.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onVerificationMail: (token) => dispatch(action.verificationMail(token)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerificationMail);
