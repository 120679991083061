import * as actionTypes from "../Actions/actionTypes";

const initialState = {
  loading: false,
  msg: null,
  error: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.VERIFICATION_FAIL:
      return { ...state, error: action.payload };
    case actionTypes.VERIFICATION_SUCCESS:
      return { ...state, msg: action.payload };

    case actionTypes.VERIFICATION_START:
      return { ...state, loading: true };
    case actionTypes.VERIFICATION_END:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default reducer;
