export default {
  fonts: ["Jura", "Teko", "Annie+Use+Your+Telescope", "Roboto"],
  radii: [0, "4px", "8px", "16px", "32px", "50%"],

  colors: {
    first: "#a231cf",
    second: "#51cec5",
    third: "#00b7ab",
    fourt: "",
    hover: {
      violet: "#BE7DD8",
      cyan: "#9feee8",
      violettwo: "#BE7DD860",
      cyantwo: "#9feee860",
    },
  },
};
