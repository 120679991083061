import React, { Component } from "react";
import styled from "styled-components";

import Remove from "../../Components/remove";
import Button from "../../Components/modalBtn";

const StyledBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99999;
`;

const StyledContainer = styled.div`
  position: fixed;
  background: transparent;
  width: 600px;
  height: 600px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.7);
`;

const StyledAlert = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #ffffff;
  width: 100%;
  height: 100%;
`;

const AlertTitle = styled.div`
  font-family: "Teko", sans-serif;
  font-size: 2rem;
  display: flex;
  background: white;
  width: 100%;
  height: 220px;
  align-items: flex-end;
  justify-content: center;
`;

const AlertBody = styled.div`
  font-family: "Jura", sans-serif;
  font-size: 1rem;
  text-align: center;
  display: flex;
  background: #ffffff;
  height: auto;
  margin: 10px 30px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AlertFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background: #9feee8;
  border-top: 2px solid #51cec5;
  width: 100%;
  height: 85px;
`;

class RemoveModal extends Component {
  state = {
    isOpen: false,
  };

  handleOpenModal = (event) => {
    event.preventDefault();
    this.setState({ isOpen: true });
  };

  handleCloseModal = (event) => {
    event.preventDefault();
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <>
        <Remove onClick={this.handleOpenModal} />
        {this.state.isOpen === true ? (
          <StyledBackground>
            <StyledContainer>
              <StyledAlert>
                <AlertTitle>You are about to delete a Card</AlertTitle>
                <AlertBody>
                  <p>Are you sure you want to proceed?</p>
                  <p>
                    Once done you will lose all the information of the Card
                    <br /> and the QR will be useless
                  </p>
                </AlertBody>
                <AlertFooter>
                  <Button type="button" onClick={this.handleCloseModal}>
                    CANCEL
                  </Button>
                  <Button type="button" onClick={this.props.removeCard}>
                    CONTINUE
                  </Button>
                </AlertFooter>
              </StyledAlert>
            </StyledContainer>
          </StyledBackground>
        ) : null}
      </>
    );
  }
}

export default RemoveModal;
