import React from "react";
import styled from "styled-components";
import StyleHl from "../Utils/StyleHelper";

const Wrap = styled.div`
  background-color: white;
  height: 30px;
  width: 30px;
  margin: auto;
  margin-top: 5px;
`;

const SvgImg = styled.svg`
  fill: #a231cf;
`;

export default ({ filled }) => (
  <Wrap filled={filled} title="Edit Card">
    <SvgImg
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 132 131.8"
    >
      <g data-name="Grupo 16">
        <rect
          data-name="Rectángulo 96"
          x="129.8"
          y="24.3"
          width="17.5"
          height="45.1"
          transform="translate(-18.21 86.98)rotate(-45)"
        />
        <rect
          data-name="Rectángulo 97"
          x="33.9"
          y="75.1"
          width="107.8"
          height="45.1"
          transform="translate(-69.01 65.93)rotate(-45)"
        />
        <path data-name="Polígono 3" d="M0 134.9l5.2-29.3L29.4 129.7Z" />
      </g>
    </SvgImg>
  </Wrap>
);
