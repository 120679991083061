import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as action from "../../Store/Actions/index";

import Switch from "../../Components/switch";
import Input from "../../Components/input";
import Label from "../../Components/textLabel";
import Submit from "../../Components/submitBtn";
import Content from "../../Components/contentCardWrapper";
import ImgUploader from "../helpers/imgUpload";
import Link from "../../Components/textLink";
import Spinner from "../../Components/spinner";
import Square from "../../Components/redSquare";

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  padding: 40px 0;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 60%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const BtnRow = styled.div`
  display: flex;
  width: 40%;
  justify-content: center;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

class NewCardForm extends Component {
  componentDidMount() {
    this.props.onCleanChanges();
    this.props.onSetChanges({ private: true });
  }

  handleChange = (event) => {
    const target = event.target;
    const value =
      target.type === "checkbox" ? event.target.checked : target.value;
    const name = target.name;

    const dataUpd = { ...this.props.changedData, [name]: value };
    this.props.onSetChanges(dataUpd);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    let finalData = {
      ...this.props.changedData,
      private: this.props.changedData.private,
      file: this.props.changedData.file || "",
    };

    this.props.onPostNewCard(finalData);
  };

  setEncodedImage = (encodedFile) => {
    this.props.onSetChanges({
      ...this.props.changedData,
      file: encodedFile.replace(/^data:.+;base64,/, ""),
    });
  };

  render() {
    let Screen = null;
    if (this.props.loading) {
      Screen = <Spinner />;
    }
    return (
      <Content small listation>
        {Screen}
        <Form onSubmit={this.handleSubmit}>
          <Label left>
            Card Name
            <Square />
          </Label>
          <Input
            elementType="input"
            formType
            type="text"
            name="name"
            placeholder="Card Name"
            autoFocus="autofocus"
            content={this.props.changedData.name}
            changes={this.handleChange}
          />

          <Label left>
            Title
            <Square />
          </Label>
          <Input
            elementType="input"
            formType
            type="text"
            name="title"
            placeholder="Enter the card title"
            content={this.props.changedData.title}
            changes={this.handleChange}
          />

          <Wrap>
            <Label left>Picture</Label>
            <ImgUploader onFileFinish={this.setEncodedImage} />
          </Wrap>

          <Label left>
            Description
            <Square />
          </Label>
          <Input
            elementType="textarea"
            name="body"
            placeholder="Enter the card description"
            content={this.props.changedData.body}
            changes={this.handleChange}
          />

          <Content full row center>
            <Label short small>
              Public
            </Label>
            <Switch
              name="private"
              onChange={this.handleChange}
              isPrivate={this.props.changedData.private}
            />
            <Label short small>
              Private
            </Label>
          </Content>
          <BtnRow>
            <Submit
              changed={this.props.dirtyChanges}
              disabled={
                !this.props.changedData.name ||
                !this.props.changedData.title ||
                !this.props.changedData.body
              }
            >
              SAVE
            </Submit>
          </BtnRow>

          <Content>
            <Link to="/my_cards">CANCEL</Link>
          </Content>
        </Form>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    changedData: state.mod.changedData,
    dirtyChanges: state.mod.dirtyChanges,
    loading: state.cards.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onPostNewCard: (data) => dispatch(action.postNewCard(data)),
    onSetChanges: (data) => dispatch(action.setChanges(data)),
    onCleanChanges: () => dispatch(action.cleanChanges()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(NewCardForm);
