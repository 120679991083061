import * as actionTypes from "./actionTypes";
import history from "../../Containers/helpers/history";

export const verificationMail = (token) => {
  return (dispatch) => {
    dispatch(verificationStart());
    fetch(`${process.env.REACT_APP_API_URL}/v1/verification`, {
      method: "post",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: token }),
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((resp) => {
              dispatch(
                verificacionSuccess(
                  "Verification Success! redirecting to Sign In page"
                )
              );
            })
            .then((user) => {
              history.push("/log_in");
            })
            .catch((error) => console.log(error));
        } else if (response.status === 401) {
          dispatch(verificationFail("There was a problem, please try again"));
        } else if (response.status === 404) {
          history.push(`/404`);
        }
      })
      .catch((error) => {
        dispatch(verificationFail(error));
      })
      .finally(() => {
        dispatch(verificationEnd());
      });
  };
};

export const verificationFail = (error) => {
  return {
    type: actionTypes.VERIFICATION_FAIL,
    payload: error,
  };
};

export const verificacionSuccess = (data) => {
  return {
    type: actionTypes.VERIFICATION_SUCCESS,
    payload: data,
  };
};

export const verificationStart = () => {
  return {
    type: actionTypes.VERIFICATION_START,
  };
};

export const verificationEnd = () => {
  return {
    type: actionTypes.VERIFICATION_END,
  };
};
