import React from "react";
import styled from "styled-components";
import StyleHl from "../Utils/StyleHelper";

const Content = styled.div`
    width: 75%;
    height: auto;
    display: flex;
    margin: auto;
    padding: 43px 0;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    @media (max-width: 1024px) {
        width: 100%;
    }    

`;

const TitleOne = styled.p`
    display: flex;
    font-family: "Teko", sans-serif;
    line-height: 1.2em;
    margin: 0;
    font-size: 7em;
    top:95px;
    left: -70px;
    position: relative;
    z-index:9999;
    opacity: 0.7;

    @media (max-width: 1024px) {
        font-size: 4em;
        top: 50px;
        left: -80px;
    }    
`;

const TitleTwo = styled.p`
    display: flex;
    font-family: "Teko", sans-serif;
    line-height: 1.2em;
    margin: 0;
    font-size: 9em;
    color: ${StyleHl.colors.first};
    opacity: 0.5;
    filter: drop-shadow(-8px 7px 0px #00000010);

    @media (max-width: 1024px) {
        font-size: 5.8em;
        white-space: nowrap;
    }    
`;

const TitleThree = styled.p`
    display: flex;
    font-family: "Teko", sans-serif;
    line-height: 1.2em;
    margin: 0;
    font-size: 5em;
    top: -80px;
    left: 60px;
    position: relative;
    opacity: 0.8;

    @media (max-width: 1024px) {
        font-size: 4em;
        top: -60px;
        left: 30px;
    }    
`;

const HomeTitle = () => (
    <Content >
        <TitleOne>IDENTIFY</TitleOne>
        <TitleTwo>YOUR THINGS</TitleTwo>
        <TitleThree>WITH SQR HUB</TitleThree>
    </Content>
);

export default HomeTitle;