import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";
import Logo from "./logo";
import ImgDesk from "../Img/back-black-A.svg";
import LinkBtn from "./textLink";

const ImgHeader = styled.div`
  width: 100%;
  padding-top: 10px;
  height: 220px;
  background-image: url("${ImgDesk}");
  position: relative;
  top: -2px;

  @media (max-width: 1024px) {
    height: 144px;
    padding: 0;
    position: relative;
    top: -1px;
  }
`;

const Header = styled.div`
  background-color: black;
  color: white;
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

const Line = styled.div`
  border-left: 3px solid cyan;
  height: 40px;
`;

const BtnBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
  min-width: 300px;

  @media (max-width: 1024px) {
    min-width: 200px;
    padding: 10px;
    align-items: center;
  }
`;

const UserName = styled(Link)`
  font-family: "Jura", sans-serif;
  color: white;
  display:flex;
  align-items: center;
  justify-content: center;
  tab-size: 1.3rem;
  padding: 0px 15px;
  height: 45px;
  text-decoration: none;

  :focus {
    outline: none;
    box-shadow: 0px 0px 5px #585858;
  }
`;

const HeaderBar = ({ user, deleteUser, location, mobile }) => {
  let NavBar = null;
  if (user && mobile === false) {
    NavBar = (
      <BtnBar>
        <UserName to="/my_cards">{user.email}</UserName>
        <Line />
        <LinkBtn
          home="home"
          isthere={location === "/" ? "true" : "false"}
          to={`/`}
          onClick={deleteUser}
        >
          Log Out
        </LinkBtn>
      </BtnBar>
    );
  } else if (user && mobile === true) {
    NavBar = (
      <BtnBar> 
        {/* avatar de user con dropdown */}
        <LinkBtn
          home="home"
          isthere={location === "/" ? "true" : "false"}
          to={`/`}
          onClick={deleteUser}
        >
          Log Out
        </LinkBtn>
      </BtnBar>
    );
  } else if (!user && mobile === true) {
    NavBar = (
      <BtnBar>
        <LinkBtn
          home="home"
          isthere={location === "/log_in" ? "true" : "false"}
          to={`/log_in`}
        >
          Log In
        </LinkBtn>
        <Line />
        <LinkBtn
          home="home"
          isthere={location === "/sign_up" ? "true" : "false"}
          to={`/sign_up`}
        >
          Sign Up
        </LinkBtn>
      </BtnBar>
    );
  } else {
    NavBar = (
      <BtnBar>
        <LinkBtn
          home="home"
          isthere={location === "/log_in" ? "true" : "false"}
          to={`/log_in`}
        >
          Log In
        </LinkBtn>
        <Line />
        <LinkBtn
          home="home"
          isthere={location === "/sign_up" ? "true" : "false"}
          to={`/sign_up`}
        >
          Sign Up
        </LinkBtn>
      </BtnBar>
    );
  }

  return (
    <div>
      <Header>
        <Link to={user ? `/my_cards` : `/`} replace>
          <Logo />
        </Link>
        {NavBar}
      </Header>
      <ImgHeader mobile={mobile} />
    </div>
  );
};

export default HeaderBar;
