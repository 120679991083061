import styled from "styled-components";

import StyleHl from "../Utils/StyleHelper";
import { Link } from "react-router-dom";

export default styled(Link)`
  font-family: "Teko", sans-serif;
  background: ${(props) => (props.filled ? StyleHl.colors.first : "white")};
  color: ${(props) => (props.filled ? "white" : StyleHl.colors.first)};
  width: 50px;
  height: 40px;
  border: 2px solid ${StyleHl.colors.first};
  border-radius: 3px;
  line-height: 1.1rem;
  text-decoration: none;
  margin: auto;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: center;

  :focus {
    outline: none;
    box-shadow: 0px 0px 5px #be7dd8;
  }

  :hover {
    background: ${(props) =>
      props.filled ? StyleHl.colors.hover.violet : "white"};
    color: ${(props) => (props.filled ? "white" : StyleHl.colors.hover.violet)};
    border: 2px solid ${StyleHl.colors.hover.violet};
    cursor: pointer;
  }

  :hover svg {
    fill: ${(props) => (props.filled ? "white" : "#be7dd8")};
  }
`;
