import React, { Component } from "react";
import { connect } from "react-redux";
import ReactMarkdown from "react-markdown";
import * as action from "../Store/Actions/index";
import history from "./helpers/history";

import { Link } from "react-router-dom";

import Content from "../Components/contentCardWrapper";
import Title from "../Components/textTitle";
import Text from "../Components/textLabel";
import Edit from "../Components/edit";
import Print from "../Components/print";
import RemoveModal from "./modals/modalRemove";
import Spinner from "../Components/spinner";
import styled from "styled-components";
import Colors from "../Utils/StyleHelper";
import TextLink from "../Components/textLink";
import SecondBtn from "../Components/secondBtn";

const Container = styled.div`
  position: relative;
  text-align: center;
  height: 200px;
  width: 200px;
  border: 2px ${Colors.colors.second} solid;
  border-radius: 3px;
  margin: auto;
`;

const BtnRow = styled.div`
  margin: 40px 0;
  display: flex;
  width: 40%;
  padding: 0;
  justify-content: space-around;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const ImgCard = styled.img`
  display: block;
  width: 100%;
`;

const Wrap = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px auto;

  @media (max-width: 1024px) {
    width: 100%;
    margin: 25px 15px;
  }
`;

class ViewCard extends Component {
  componentDidMount() {
    if (this.props.match.params.uuid) {
      this.props.onGetCard(this.props.match.params.uuid);
    }
  }

  handleRemoveCard = () => {
    this.props.onRemoveCard(this.props.match.params.uuid);
  };

  render() {
    let Screen = null;
    if (this.props.loading) {
      Screen = <Spinner />;
    }

    return (
      <Wrap>
        <Content small view>
          {Screen}
          <Title view="true">{this.props.card.title}</Title>
          <Container>
            <ImgCard src={this.props.card.file_url} alt="" />
          </Container>
          <Content>
            <Text left wrap>
              <ReactMarkdown>{this.props.card.body}</ReactMarkdown>
            </Text>
          </Content>
        </Content>

        <BtnRow>
          <RemoveModal removeCard={this.handleRemoveCard} />

          <SecondBtn to={`/edit/${this.props.match.params.uuid}`}>
            <Edit />
          </SecondBtn>

          <SecondBtn filled to={`/print/${this.props.match.params.uuid}`}>
            <Print filled />
          </SecondBtn>
        </BtnRow>

        <Content>
          <TextLink to="/my_cards">MY CARDS</TextLink>
        </Content>
      </Wrap>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    card: state.cards.card,
    loading: state.cards.loading,
    isAuth: state.auth.isAuth,
    user: state.auth.user,
    userEmail: state.auth.userEmail,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCard: (uuid) => dispatch(action.getCard(uuid)),
    onRemoveCard: (uuid) => dispatch(action.deleteCard(uuid)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewCard);
