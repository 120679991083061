import React from "react";
import styled from "styled-components";

const Spinner = styled.div`
  position: absolute;
  left: 48.7%;
  top: 50%;
  border: 8px solid #ddffff;
  border-radius: 50%;
  border-top: 8px solid cyan;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 0.5s linear infinite; /* Safari */
  animation: spin 0.5s linear infinite;
  margin: auto;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const Back = styled.div`
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.7);
`;

export default () => (
  <Back>
    <Spinner />
  </Back>
);
