import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../Store/Actions/index";
import history from "../helpers/history";
import StyleHelper from "../../Utils/StyleHelper";

import Content from "../../Components/contentCardWrapper";
import Label from "../../Components/textLabel";
import Input from "../../Components/input";
import Link from "../../Components/textLink";
import Helper from "../../Components/textHelper";
import Submit from "../../Components/inputBtn";
import Spinner from "../../Components/spinner";
import Google from "../../Components/googleButton";

//TODO: While converting this component to functions and hook
//please change to 'useGoogleRecaptcha'
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin: 20px 0;
`;

const StyledButton = styled.a`
  height: 40px;
  border: 1px solid #a231cf;
  display: flex;
  width: 182px;
  align-items: center;
  border-radius: 5px;
  text-decoration: none;
  color: gray;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 2px #be7dd880;
  }
`;

const StyledText = styled.div`
  font-family: ${StyleHelper.fonts[3]};
  font-size: 14px;
`;

const StyledReCaptcha = styled.div`
  font-size: 14px;
  color: gray;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;

  & > p {
    margin: 0%;
    padding: 0%;
    white-space: nowrap;
  }

  @media (max-width: 800px) {
    padding: 0px 15px;
  }
`;

const StyledLink = styled.a`
  margin: 0px 4px;
  white-space: nowrap;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  justify-content: center;
  align-content: center;

  @media (max-width: 1024px) {
    width: 90%;
  }
`;

class SignUpForm extends Component {
  state = {
    email: null,
    password: null,
    passwordConf: null,
    recaptcha: null,

    validations: {
      email: {
        valid: true,
        msg: "Insert valid email.",
      },
      password: {
        valid: true,
        msg: "Minimun length 6.",
      },
      passwordConf: {
        valid: true,
        msg: "Password doesn't match.",
      },
    },
  };

  componentDidMount() {
    if (this.props.isAuth) {
      history.push("/my_cards");
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value }, () => this.checkValidity(name));
  };

  updateValidations = (value) => {
    const newValidations = {
      ...this.state.validations,
      value,
    };
    this.setState({ validations: newValidations });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const data = {
      email: this.state.email,
      password: this.state.password,
      recaptcha: this.state.recaptcha,
    };
    this.props.onNewUser(data);
  };

  handleRecaptchaResponse = (token) => {
    this.setState({ recaptcha: token });
  };

  checkValidity = (name) => {
    const validation = this.state.validations[name];
    const valueToCheck = this.state[name];

    switch (name) {
      case "email": {
        const pattern =
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        validation.valid = pattern.test(valueToCheck);
        this.updateValidations(validation);
        break;
      }
      case "password": {
        validation.valid = valueToCheck.length >= 6;
        this.updateValidations(validation);
        break;
      }
      case "passwordConf": {
        validation.valid = valueToCheck === this.state.password;
        this.updateValidations(validation);
        break;
      }
      default:
        break;
    }
  };

  isFormValid = () => {
    return (
      this.state.validations.email.valid &&
      this.state.validations.password.valid &&
      this.state.validations.passwordConf.valid
    );
  };

  render() {
    let Screen = null;
    if (this.props.loading) {
      Screen = <Spinner />;
    }

    return (
      <Content small>
        {Screen}
        <Form onSubmit={this.handleSubmit}>
          <Helper center>
            WELCOME TO SQR HUB! <br /> YOUR PERSONAL TAG EVERYWHERE
          </Helper>

          <Label>E-MAIL</Label>
          <Input
            elementType="input"
            type="email"
            name="email"
            content={this.state.email}
            changes={this.handleChange}
            validation={this.state.validations.email}
          />
          <Label>PASSWORD</Label>
          <Input
            elementType="input"
            name="password"
            type="password"
            content={this.state.password}
            changes={this.handleChange}
            validation={this.state.validations.password}
          />

          <Label>CONFIRM PASSWORD</Label>
          <Input
            elementType="input"
            name="passwordConf"
            type="password"
            content={this.state.passwordConf}
            changes={this.handleChange}
            validation={this.state.validations.passwordConf}
          />

          <StyledDiv>
            <StyledReCaptcha>
              <p>This site is protected by reCAPTCHA and the Google</p>
              <StyledLink href="https://policies.google.com/privacy">
                Privacy Policy
              </StyledLink>
              <p>and</p>
              <StyledLink href="https://policies.google.com/terms">
                Terms of Service
              </StyledLink>
              <p>apply.</p>
              <GoogleReCaptcha onVerify={this.handleRecaptchaResponse} />
            </StyledReCaptcha>
          </StyledDiv>

          <Content listation>
            <Submit
              type="submit"
              disabled={!this.isFormValid()}
              value="REGISTER"
            ></Submit>
          </Content>

          <Content listation>
            <StyledButton
              href={`${process.env.REACT_APP_API_URL}/v1/auth/google`}
            >
              <Google />
              <StyledText>Sign up with Google</StyledText>
            </StyledButton>
          </Content>

          <Content listation>
            <Link to="/log_in" small>
              ALREADY HAVE AN ACCOUNT
            </Link>
          </Content>
        </Form>
      </Content>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onNewUser: (data) => dispatch(action.postNewUser(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SignUpForm)
);
