import React from "react";
import styled from "styled-components";
import StyleHl from "../Utils/StyleHelper";

const Wrap = styled.div`
  width: 50px;
  height: 40px;
  display: ${(props) => (props.hidden ? "none;" : "flex;")};
  justify-content: center;
  align-items: center;
  border: 2px solid ${StyleHl.colors.first};
  border-radius: 3px;
  background: ${(props) => (props.filled ? StyleHl.colors.first : "white")};
  color: ${(props) => (props.filled ? "white" : StyleHl.colors.first)};

  :focus {
    outline: none;
    box-shadow: 0px 0px 5px #be7dd8;
  }

  :hover {
    background: ${(props) =>
      props.filled ? StyleHl.colors.hover.violet : "white"};
    color: ${(props) => (props.filled ? "white" : StyleHl.colors.hover.violet)};
    border: 2px solid ${StyleHl.colors.hover.violet};
    cursor: pointer;
  }

  :hover svg {
    fill: #be7dd8;
  }
`;

const SvgImg = styled.svg`
  height: 30px;
  fill: #a231cf;
`;

export default ({ filled, onClick, hidden }) => (
  <Wrap filled={filled} onClick={onClick} hidden={hidden}>
    <SvgImg
      version="1.1"
      id="Capa_1"
      x="0px"
      y="0px"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <title>cancelIcon</title>
      <polygon
        class="st0"
        points="19,4.8 15.2,1 10,6.3 4.8,1 1,4.8 6.3,10 1,15.2 4.8,19 10,13.7 15.2,19 19,15.2 13.7,10 "
      />
    </SvgImg>
  </Wrap>
);
