import React from "react";
import styled from "styled-components";

import StyleHl from "../Utils/StyleHelper";

const InputBtn = styled.input`
  font-family: "Teko", sans-serif;
  background: ${(props) =>
    props.disabled ? "gray" : `${StyleHl.colors.first}`};
  color: white;
  width: 120px;
  height: 40px;
  font-size: 1.7em;
  border: none;
  border-radius: 3px;
  margin-top: 25px;
  line-height: 1.6em;
  outline: none;

  :hover {
    background: ${(props) =>
      props.disabled ? "gray" : `${StyleHl.colors.hover.violet}`};
    border: none;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
`;

const Btn = (props) => <InputBtn {...props}></InputBtn>;

export default Btn;
