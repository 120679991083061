import styled from "styled-components";

export default styled.div`
  width: ${(props) => (props.view ? "80%" : "50%")};
  display: flex;
  font-family: "Jura", sans-serif;
  font-size: ${(props) => (props.view ? "2em" : "4em")};
  line-height: 1.2em;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 20px 0;
  text-align: ${(props) => (props.view ? "center" : "none")};
`;
