import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../Store/Actions/index";
import history from "../helpers/history";
import StyleHelper from "../../Utils/StyleHelper";

import Content from "../../Components/contentCardWrapper";
import Label from "../../Components/textLabel";
import Input from "../../Components/input";
import Link from "../../Components/textLink";
import Submit from "../../Components/inputBtn";
import Spinner from "../../Components/spinner";
import Google from "../../Components/googleButton";

//TODO: While converting this component to functions and hook
//please change to 'useGoogleRecaptcha'
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin: 20px 0;
`;

const StyledButton = styled.a`
  height: 40px;
  border: 1px solid #a231cf;
  display: flex;
  width: 182px;
  align-items: center;
  border-radius: 5px;
  text-decoration: none;
  color: gray;

  &:hover {
    cursor: pointer;
    box-shadow: 0px 2px 2px #be7dd880;
  }
`;

const StyledText = styled.div`
  font-family: ${StyleHelper.fonts[3]};
  font-size: 14px;
`;

const StyledReCaptcha = styled.div`
  font-size: 14px;
  color: gray;
  margin: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;

  & > p {
    margin: 0%;
    padding: 0%;
    white-space: nowrap;
  }

  @media (max-width: 800px) {
    padding: 0px 15px;
  }
`;

const StyledLink = styled.a`
  margin: 0px 4px;
  white-space: nowrap;
`;

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 60%;
  justify-content: center;
  align-content: center;

  @media (max-width: 1024px) {
    width: 90%;
  }
`;

class LogInForm extends Component {
  state = {
    invalid: false,
  };

  componentDidMount() {
    if (this.props.isAuth) {
      history.push("/my_cards");
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const data = { ...this.props.changedData, [name]: value };
    this.props.onSetChanges(data);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onAuthUser(this.props.changedData);
    /* localStorage.setItem(this.props.isAuth, this.props.user) */
  };

  handleRecaptchaResponse = (token) => {
    const data = { ...this.props.changedData, recaptcha: token };
    this.props.onSetChanges(data);
  };

  render() {
    let Screen = null;
    if (this.props.loading) {
      Screen = <Spinner />;
    }
    return (
      <Content small>
        {Screen}
        <Form onSubmit={this.handleSubmit}>
          <Label>ENTER YOUR E-MAIL</Label>
          <Input
            elementType="input"
            type="email"
            name="email"
            content={this.props.changedData.email}
            changes={this.handleChange}
          />

          <Label>ENTER YOUR PASSWORD</Label>
          <Input
            elementType="input"
            type="password"
            name="password"
            content={this.props.changedData.password}
            changes={this.handleChange}
          />

          <StyledDiv>
            <StyledReCaptcha>
              <p>This site is protected by reCAPTCHA and the Google</p>
              <StyledLink href="https://policies.google.com/privacy">
                Privacy Policy
              </StyledLink>
              <p>and</p>
              <StyledLink href="https://policies.google.com/terms">
                Terms of Service
              </StyledLink>
              <p>apply.</p>
              <GoogleReCaptcha onVerify={this.handleRecaptchaResponse} />
            </StyledReCaptcha>
          </StyledDiv>

          <Content>
            <Submit
              type="submit"
              value="LOG IN"
              disabled={
                !this.props.changedData.email ||
                !this.props.changedData.password
              }
            ></Submit>
          </Content>

          <Content listation>
            <StyledButton
              href={`${process.env.REACT_APP_API_URL}/v1/auth/google`}
            >
              <Google />
              <StyledText>Sign in with Google</StyledText>
            </StyledButton>
          </Content>

          <Content>
            <Link to="/sign_up" small="true">
              DON'T HAVE AN ACCOUNT? CREATE ONE!
            </Link>
            <Link to="/forgot_password" small="true">
              FORGOT PASSWORD?
            </Link>
          </Content>
        </Form>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    changedData: state.mod.changedData,
    isAuth: state.auth.isAuth,
    user: state.auth.user,
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSetChanges: (data) => dispatch(action.setChanges(data)),
    onAuthUser: (data) => dispatch(action.postAuthUser(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LogInForm)
);
