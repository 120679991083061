import React from "react";
import styled from "styled-components";

const Label = styled.label`
    position: relative;
    display: inline-block;
    width: 90px;
    height: 34px;    
`;

const Span = styled.span`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #9feee8;
    -webkit-transition: .1s;
    transition: .1s;

    &:before {
        position: absolute;
        content: "";
        height: 26px;
        width: 26px;
        left: 4px;
        bottom: 4px;
        background-color: #51cec5;
        -webkit-transition: .4s;
        transition: .4s;
    }
`;

const Input = styled.input`
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + ${Span} {
        background-color: #9feee8;
    }

    &:focus + ${Span} {
        box-shadow: 0 0 1px #9feee8;
    }

    &:checked + ${Span}:before {
        -webkit-transform: translateX(56px);
        -ms-transform: translateX(56px);
        transform: translateX(56px);
    }
`;

export default ({ name, isPrivate, onChange }) => (
    <Label>
        <Input
            type="checkbox"
            name={name}
            onChange={onChange}
            checked={isPrivate} />
        <Span />
    </Label>
);