import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import * as action from "../Store/Actions/index";

import Switch from "../Components/switch";
import Input from "../Components/input";
import Label from "../Components/textLabel";
import Submit from "../Components/submitBtn";
import Content from "../Components/contentCardWrapper";
import ImgUploader from "./helpers/imgUpload";
import Spinner from "../Components/spinner";
import Link from "../Components/textLink";
import Square from "../Components/redSquare";

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  padding: 40px 0;
`;

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 60%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const BtnRow = styled.div`
  display: flex;
  width: 40%;
  justify-content: center;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

class EditCardForm extends Component {
  componentDidMount() {
    this.props.onGetCard(this.props.match.params.uuid);
    this.props.onCleanChanges();
  }

  handleChange = (event) => {
    const target = event.target;
    const value =
      target.type === "checkbox" ? event.target.checked : target.value;
    const name = target.name;

    const dataUpd = { ...this.props.changedData, [name]: value };

    this.props.onSetChanges(dataUpd);
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onEditCard(this.props.match.params.uuid, this.props.changedData);
  };

  setEncodedImage = (encodedFile) => {
    this.props.onSetChanges({
      ...this.props.changedData,
      file: encodedFile.replace(/^data:.+;base64,/, ""),
    });
  };

  currentOrDirty = (valueName) => {
    const value = this.props.changedData[valueName];

    if (value !== null && value !== undefined) {
      return value;
    } else {
      return this.props.data[valueName];
    }
  };

  render() {
    let Screen = null;
    if (this.props.loading) {
      Screen = <Spinner />;
    }

    return (
      <Content small="small">
        {Screen}
        <Form onSubmit={this.handleSubmit}>
          <Label left>
            Card Name
            <Square />
          </Label>
          <Input
            elementType="input"
            formType
            type="text"
            name="name"
            placeholder="Card Name"
            autoFocus="autofocus"
            content={this.currentOrDirty("name")}
            changes={this.handleChange}
          />

          <Label left>
            Title
            <Square />
          </Label>
          <Input
            elementType="input"
            formType
            type="text"
            name="title"
            placeholder="Enter the card title"
            content={this.currentOrDirty("title")}
            changes={this.handleChange}
          />

          <Wrap>
            <Label left>Picture</Label>
            <ImgUploader onFileFinish={this.setEncodedImage} />
          </Wrap>

          <Label left>
            Description
            <Square />
          </Label>
          <Input
            elementType="textarea"
            name="body"
            placeholder="Enter the card description"
            content={this.currentOrDirty("body")}
            changes={this.handleChange}
          />

          <Content row>
            <Label short small>
              Public
            </Label>
            <Switch
              name="private"
              onChange={this.handleChange}
              isPrivate={this.currentOrDirty("private")}
            />
            <Label short small>
              Private
            </Label>
          </Content>
          <BtnRow>
            <Submit
              changed={this.props.dirtyChanges}
              disabled={!this.props.dirtyChanges}
            >
              {" "}
              SAVE
            </Submit>
          </BtnRow>

          <Content>
            <Link to="/my_cards">CANCEL</Link>
          </Content>
        </Form>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    data: state.cards.card,
    isAuth: state.auth.isAuth,
    user: state.auth.user,
    userEmail: state.auth.userEmail,
    changedData: state.mod.changedData,
    dirtyChanges: state.mod.dirtyChanges,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetCard: (uuid) => dispatch(action.getCard(uuid)),
    onEditCard: (uuid, data) => dispatch(action.editCard(uuid, data)),
    onSetChanges: (data) => dispatch(action.setChanges(data)),
    onCleanChanges: () => dispatch(action.cleanChanges()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditCardForm);
