import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "./Store/Actions/index";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import styled from "styled-components";
import StyleHl from "./Utils/StyleHelper";

import Home from "./Containers/home";
import PrivacyPolicy from "./Containers/privacyPolicy";
import TermsOfService from "./Containers/termsOfService";
import LogIn from "./Containers/forms/authForm";
import SignUp from "./Containers/forms/signupForm";
import MyCards from "./Containers/myCards";
import NewCard from "./Containers/forms/newcardForm";
import EditCard from "./Containers/editCard";
import ViewCard from "./Containers/viewCard";
import ScannedCard from "./Containers/ScannedCard";
import PrintCard from "./Containers/printCard";
import Screen404 from "./Containers/screen404";
import VerificationMail from "./Containers/verificationMail";
import ForgotPassword from "./Containers/forms/forgotPassword";
import ResetPassword from "./Containers/forms/resetPassword";

import Printer from "./Containers/printer";

import Notification from "./Components/notification";
import Header from "./Components/header";
import Wrapper from "./Components/firstWrapper";
import Footer from "./Components/footer";

const BgCyan = styled.div`
  font-family: "Teko", sans-serif;
  width: 100%;
  height: 60px;
  background-color: ${StyleHl.colors.second};
  padding: 0;
  margin: 0;
  color: white;
  text-align: center;
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  min-height: 400px;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media (max-width: 1024px) {
    min-height: 70vh;
  }
`;

const WrapFooter = styled.div`
  display: flex;
  flex-flow: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
`;

const FlexRow = styled.div`
  width: 100%;
  background-color: ${StyleHl.colors.second};
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  justify-content: space-evenly;
  align-items: center;
  margin: 0;
  padding: 0;
`;

const Flex = styled.div`
  width: ${(props) => (props.small ? "200px" : "300px")};
  background-color: ${StyleHl.colors.second};
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
`;

const StyledLink = styled.a`
  font-family: "Teko", sans-serif;
  color: white;
  text-align: center;
  font-size: 23px;
  text-decoration: none;
`;

class DebugRouter extends BrowserRouter {
  constructor(props) {
    super(props);
    console.log("initial history is: ", JSON.stringify(this.history, null, 2));
    this.history.listen((location, action) => {
      console.log(
        `The current URL is ${location.pathname}${location.search}${location.hash}`
      );
      console.log(
        `The last navigation action was ${action}`,
        JSON.stringify(this.history, null, 2)
      );
    });
  }
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = { height: 512 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.props.onGetUser();
    console.log(this.props);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      this.updateWindowDimensions.bind(this)
    );
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  render() {
    let mobile = false;
    if (this.state.width <= 1024) {
      mobile = true;
    }

    let routes = (
      <Switch>
        <Route path="/printer" component={Printer} />

        <Route path="/" exact component={() => <Home mobile={mobile} />} />
        <Route path="/privacy_policy" exact component={PrivacyPolicy} />
        <Route path="/terms_of_service" exact component={TermsOfService} />
        <Route path="/log_in" exact component={LogIn} />
        <Route path="/sign_up" exact component={SignUp} />
        <Route path="/my_cards" exact component={MyCards} />
        <Route path="/new_card" exact component={NewCard} />
        <Route path="/view/:uuid" exact component={ViewCard} />
        <Route path="/edit/:uuid" component={EditCard} />
        <Route path="/print/:uuid" component={PrintCard} />
        <Route path="/404" exact component={Screen404} status={404} />
        <Route path="/verification/:token" component={VerificationMail} />
        <Route path="/forgot_password" component={ForgotPassword} />
        <Route path="/reset_password/:token" component={ResetPassword} />
        <Route path="/:uuid" component={ScannedCard} status={200} />

        <Redirect to="/" />
      </Switch>
    );

    return (
      <Wrapper>
        <Header
          mobile={mobile}
          location={window.location.pathname}
          user={this.props.user}
          deleteUser={this.props.onDeleteUser}
        />
        <Wrap>{routes}</Wrap>
        {this.props.authError ? <Notification /> : null}
        {this.props.cardError ? <Notification /> : null}
        <WrapFooter>
          <Footer mirror top />
          <FlexRow>
            <Flex>
              <BgCyan>
                <StyledLink href="/privacy_policy">Privacy Policy </StyledLink>
              </BgCyan>
              <BgCyan>
                <StyledLink href="/terms_of_service">
                  Terms of Service
                </StyledLink>
              </BgCyan>
            </Flex>
            <Flex small>
              <BgCyan> Copyright &copy; 2020 SQR HUB </BgCyan>
            </Flex>
          </FlexRow>
        </WrapFooter>
      </Wrapper>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    isAuth: state.auth.isAuth,
    cardError: state.cards.error,
    authError: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUser: () => dispatch(action.getUser()),
    onDeleteUser: () => dispatch(action.deleteUser()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
