import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as action from "../../Store/Actions/index";
import history from "../helpers/history";

import Content from "../../Components/contentCardWrapper";
import Label from "../../Components/textLabel";
import Input from "../../Components/input";
import Helper from "../../Components/textHelper";
import Submit from "../../Components/inputBtn";
import Spinner from "../../Components/spinner";

const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  margin: 20px 0;
`;

class ResetForm extends Component {
  state = {
    email: null,
    password: null,
    passwordConf: null,

    validations: {
      email: {
        valid: true,
        msg: "Insert valid email.",
      },
      password: {
        valid: true,
        msg: "Minimun length 6.",
      },
      passwordConf: {
        valid: true,
        msg: "Password doesn't match.",
      },
    },
  };

  componentDidMount() {
    if (this.props.isAuth) {
      history.push("/my_cards");
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({ [name]: value }, () => this.checkValidity(name));
  };

  updateValidations = (value) => {
    const newValidations = {
      ...this.state.validations,
      value,
    };
    this.setState({ validations: newValidations });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    const data = { token: this.props.match.params.token, password: this.state.password };
    this.props.onResetPassword(data);
  };

  checkValidity = (name) => {
    const validation = this.state.validations[name];
    const valueToCheck = this.state[name];

    switch (name) {
      case "password": {
        validation.valid = valueToCheck.length >= 6;
        this.updateValidations(validation);
        break;
      }
      case "passwordConf": {
        validation.valid = valueToCheck === this.state.password;
        this.updateValidations(validation);
        break;
      }
      default:
        break;
    }
  };

  isFormValid = () => {
    return (
      this.state.validations.password.valid &&
      this.state.validations.passwordConf.valid
    );
  };

  render() {
    let Screen = null;
    if (this.props.loading) {
      Screen = <Spinner />;
    }

    return (
      <Content small>
        {Screen}
        <Form onSubmit={this.handleSubmit}>
          <Helper center>WE ARE ALMOST DONE!</Helper>

          <Label>NEW PASSWORD</Label>
          <Input
            elementType="input"
            name="password"
            type="password"
            content={this.state.password}
            changes={this.handleChange}
            validation={this.state.validations.password}
          />

          <Label>CONFIRM NEW PASSWORD</Label>
          <Input
            elementType="input"
            name="passwordConf"
            type="password"
            content={this.state.passwordConf}
            changes={this.handleChange}
            validation={this.state.validations.passwordConf}
          />
          <Content listation>
            <Submit
              type="submit"
              disabled={!this.isFormValid()}
              value="SAVE"
            ></Submit>
          </Content>
        </Form>
      </Content>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResetPassword: (data) => dispatch(action.resetPassword(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetForm)
);
