import * as actionTypes from "./actionTypes";
import history from "../../Containers/helpers/history";

export const postNewUser = (data) => {
  return (dispatch) => {
    dispatch(authRequestStart());
    fetch(`${process.env.REACT_APP_API_URL}/v1/sign_up`, {
      method: "post",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((user) => {
              dispatch(setNewUser(user));
            })
            .then((user) => {
              history.push("/my_cards");
            })
            .catch((error) => console.log(error));
        } else if (response.status === 401) {
          dispatch(authFail(response.status));
        } else if (response.status === 404) {
          history.push(`/404`);
        }
      })
      .catch((error) => {
        dispatch(authFail(error));
      })
      .finally(() => {
        dispatch(authRequestEnd());
      });
  };
};

export const setNewUser = (data) => {
  return {
    type: actionTypes.IS_AUTH,
    payload: data,
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    payload: error,
  };
};

export const postAuthUser = (data) => {
  return (dispatch) => {
    dispatch(authRequestStart());
    fetch(`${process.env.REACT_APP_API_URL}/v1/auth`, {
      method: "post",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((user) => {
              dispatch(setAuthUser(user));
            })
            .then((user) => {
              history.push("/my_cards");
            })
            .catch((error) => console.log(error));
        } else if (response.status === 401) {
          dispatch(
            authFail(
              "There was a problem, please verify your email and password and try again"
            )
          );
        } else if (response.status === 404) {
          history.push(`/404`);
        }
      })
      .catch((error) => {
        dispatch(authFail(error));
      })
      .finally(() => {
        dispatch(authRequestEnd());
      });
  };
};

export const setAuthUser = (data) => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    payload: data,
  };
};

export const getUser = () => {
  return (dispatch) => {
    dispatch(authRequestStart());
    fetch(`${process.env.REACT_APP_API_URL}/v1/auth`, {
      method: "get",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Credentials: "same-origin",
      },
    })
      .then((response) => {
        if (response.status === 200) {
          response.json().then((user) => {
            dispatch(setAuthUser(user));
          });
        } else if (response.status === 404) {
          history.push(`/404`);
        }
      })
      .catch((error) => {
        dispatch(authFail(error));
      })
      .finally(() => {
        dispatch(authRequestEnd());
      });
  };
};

export const deleteUser = () => {
  return (dispatch) => {
    dispatch(authRequestStart());
    fetch(`${process.env.REACT_APP_API_URL}/v1/auth`, {
      method: "delete",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Credentials: "same-origin",
      },
    })
      .then((response) => {
        dispatch(deleteUserInfo());
        history.push("/");
      })
      .catch((error) => {
        dispatch(authFail(error));
      })
      .finally(() => {
        dispatch(authRequestEnd());
      });
  };
};

export const deleteUserInfo = () => {
  return {
    type: actionTypes.DELETE_USER,
  };
};

export const authRequestStart = () => {
  return {
    type: actionTypes.AUTH_REQUEST_START,
  };
};

export const authRequestEnd = () => {
  return {
    type: actionTypes.AUTH_REQUEST_END,
  };
};

export const cleanAuthError = () => {
  return {
    type: actionTypes.CLEAN_AUTH_ERROR,
  };
};

export const requestResetPassword = (data) => {
  return (dispatch) => {
    dispatch(authRequestStart());
    fetch(`${process.env.REACT_APP_API_URL}/v1/request_reset_password`, {
      method: "post",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((user) => {
              history.push("/");
            })
            .catch((error) => console.log(error));
        } else if (response.status === 401) {
          dispatch(authFail(response.status));
        } else if (response.status === 404) {
          history.push(`/404`);
        }
      })
      .catch((error) => {
        dispatch(authFail(error));
      })
      .finally(() => {
        dispatch(authRequestEnd());
      });
  };
};

export const resetPassword = (data) => {
  return (dispatch) => {
    dispatch(authRequestStart());
    fetch(`${process.env.REACT_APP_API_URL}/v1/reset_password`, {
      method: "post",
      mode: "cors",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((user) => {
              history.push("/logi_in");
            })
            .catch((error) => console.log(error));
        } else if (response.status === 401) {
          dispatch(authFail(response.status));
        } else if (response.status === 404) {
          history.push(`/404`);
        }
      })
      .catch((error) => {
        dispatch(authFail(error));
      })
      .finally(() => {
        dispatch(authRequestEnd());
      });
  };
};
