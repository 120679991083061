import React from "react";
import PropTypes from 'prop-types';

const PrinterPage = React.forwardRef((props, ref) => {
  const {size, noTitle, elements} = props;

  return (
    <div style={{with: '100%', height: '100%', display: 'flex'}} ref={ref}>
      {
        elements.map((el) => {
          return (
            <div className="printedCard">
              <p>Hola</p>
              {el.image}
              {el.title}
            </div>
          )
        })
      }
    </div>
  );
});

PrinterPage.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  noTitle: PropTypes.bool,
  elements: PropTypes.arrayOf(PropTypes.shape({
     image: PropTypes.string.isRequired,
     title: PropTypes.string.isRequired,
   })).isRequired
};

PrinterPage.defaultProps = {
  size: 'medium',
  noTitle: false,

  //Just for testing purposes
  elements: [
    {
      image: 'naranja',
      title: 'hola'
    },
    {
      image: 'naranja',
      title: 'hola'
    },
    {
      image: 'naranja',
      title: 'hola'
    },
    {
      image: 'naranja',
      title: 'hola'
    },
    {
      image: 'naranja',
      title: 'hola'
    },
  ]
};

export default PrinterPage;
