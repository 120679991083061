import styled from "styled-components";

export default styled.div`
  font-family: "Jura", sans-serif;
  display: block;
  color: ${(props) => (props.error ? "red" : "Black")};
  width: ${(props) => (props.short ? "20%" : "60%")};
  height: auto;
  white-space: ${(props) => (props.wrap ? "normal" : "nowrap")};
  font-size: ${(props) => (props.error ? "15px" : "20px")};
  justify-content: ${(props) => (props.left ? "flex-start" : "center")};
  padding: ${(props) => (props.small ? "10px" : "25px 0 5px 0")};

  @media (max-width: 1024px) {
    width: ${(props) => (props.short ? "15%" : "90%")};
    margin: 0 20px;
  }

  @media (max-width: 600px) {
    display: flex;
    justify-content: flex-start;
  }
`;
