import React, { Component } from "react";
import { connect } from "react-redux";
import * as action from "../Store/Actions/index";
import styled from "styled-components";
import StyleHl from "../Utils/StyleHelper";

import Content from "../Components/contentCardWrapper";
import PrimaryBtn from "../Components/primaryBtn";
import Helper from "../Components/textHelper";
import Line from "../Components/line";

import CardRow from "./helpers/cardRow";
import Spinner from "../Components/spinner";

import Switch from "../Components/switchText";
import SearchImg from "../Img/search.svg";

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin: 15px;
  width: 90%;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const SearchRow = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100%;
  align-items: center;
  height: 100px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const SearchWrap = styled.form`
  display: flex;
  align-items: center;
  border: 2px solid ${StyleHl.colors.first};
  border-radius: 5px;
  width: 80%;
  height: 35px;

  :hover {
    border: 2px solid ${StyleHl.colors.hover.violet};
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const Input = styled.input`
  font-family: "Jura", sans-serif;
  border: none;
  width: 100%;
  font-size: 14px;
  height: 25px;
  display: flex;
  padding: 2px 5px;
  outline: none;
`;

const Submit = styled.input`
  font-family: "Teko", sans-serif;
  background-color: ${(props) =>
    props.disabled ? "gray" : `${StyleHl.colors.first}`};
  background-image: url(${SearchImg});
  background-size: 25px;
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 35px;
  border: none;
  border-radius: 3px;

  :hover {
    background-color: ${(props) =>
      props.disabled ? "gray" : `${StyleHl.colors.hover.violet}`};

    border: none;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  }
`;

class MyCards extends Component {
  state = {
    deepSearch: false,
    searchKey: "",
    changed: false,
  };

  componentDidMount() {
    this.props.onGetUserCards();
    this.props.onCleanCard();
  }

  toggleSearch() {
    if (!this.state.deepSearch) {
      this.setState({ deepSearch: true });
    } else {
      this.setState({ deepSearch: false });
    }
  }

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;

    this.setState({ searchKey: value });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let filterText = this.state.searchKey;
    let newList = [];

    if (filterText !== "") {
      if (this.state.deepSearch) {
        this.props.onFilterCard(filterText);
      } else {
        const currentList = this.props.userCards;
        newList = currentList.filter((card) => {
          const lc = card.name.toLowerCase();
          const filter = filterText.toLowerCase();
          return lc.includes(filter);
        });
        this.setState({ changed: true });
        this.props.onSetFilteredCards(newList);
      }
    } else {
      this.props.onSetFilteredCards(this.props.userCards);
    }
  };

  render() {
    let cards = null;
    if (!this.props.loading) {
      cards = this.props.filteredCards.map((card) => (
        <CardRow key={card.uuid} cardName={card.name} uuid={card.uuid} />
      ));
    } else {
      cards = <Spinner />;
    }

    return (
      <Content small="small" start="start">
        <Wrap>
          <Helper>MY CARDS</Helper>
          <PrimaryBtn filled="true" to={`/new_card`}>
            NEW CARD
          </PrimaryBtn>
        </Wrap>
        <Line />
        <Content left="true">
          <SearchRow onSubmit={this.handleSubmit}>
            <SearchWrap>
              <Input
                type="text"
                className="input"
                onChange={this.handleChange}
                placeholder="Search..."
              />
              <Submit type="submit" value="" />
            </SearchWrap>
            <Switch
              textLabel="Search in Card Content"
              name="searchContent"
              id="searchContent"
              onClick={() => {
                this.toggleSearch();
              }}
            />
          </SearchRow>
          {cards}
        </Content>
      </Content>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userCards: state.cards.cardsList,
    loading: state.cards.loading,
    isAuth: state.auth.isAuth,
    user: state.auth.user,

    filteredCards: state.cards.filteredCards,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUser: () => dispatch(action.getUser()),
    onGetUserCards: () => dispatch(action.getUserCards()),
    onGetCard: (uuid) => dispatch(action.getCard(uuid)),
    onCleanCard: () => dispatch(action.cleanCard()),

    onFilterCard: (lookup) => dispatch(action.filterCards(lookup)),
    onSetFilteredCards: (data) => dispatch(action.setFilteredCards(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyCards);
