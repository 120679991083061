import React, { Component } from "react";
import styled from "styled-components";
import { withRouter } from 'react-router-dom';
import StyleHl from "../Utils/StyleHelper";

import Transition from "../Components/footer";
import HomeTitle from "../Components/homeTitle";
import HomeSubtitle from "../Components/homeSubtitle";

import BigImg from "../Img/big.svg";
import FlatImg from "../Img/flat.svg";

import Search from "../Img/search_icon.svg";
import Info from "../Img/info_icon.svg";
import Safe from "../Img/safe_icon.svg";
import Contact from "../Img/contact_icon.svg";


const BgCyan = styled.div`
  background-color: ${StyleHl.colors.second};
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const BgWhite = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
   width: 100%;
   min-height: 100vh; 
`;

const TransitionOne = styled.div`
   width: 100%;
   height: auto;
`;

const TransitionTwo = styled.div`
  width: 100%;
  height: auto;  
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  
  ${props => props.mobile ? `width: 100%; margin-bottom: 80px;`
    : `width: 900px; margin-bottom: 100px;`}

  ${props => props.mt ? `margin-top: 90px;` : ``}
`;

const ImgRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center; 
  flex-flow: wrap;

  ${props => props.mobile ? `justify-content:center;`
    : `justify-content: space-between;`}
`;


const FeatureBox = styled.div`
  width: 300px;
  display: flex;
  flex-direction:column;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  margin: 20px 0px;

  img {
    height: 80px;
    filter: drop-shadow(5px 5px 0px #00000010);
  }

  p{
    font-family: "Teko", sans-serif;
    color: white;
    font-size:2.2rem;
    text-transform: uppercase;
    line-height:2.5rem;
    text-align: center;
  }  
`;



class Home extends Component {
  render() {
    
    let imgProcess = null;
    if (this.props.mobile) {
      imgProcess = FlatImg;
    } else {
      imgProcess = BigImg
    }

    return (
      <Wrapper>
        <HomeTitle />
        <TransitionOne>
          <Transition invert white top />
        </TransitionOne>
        <BgCyan>
          <ImageContainer mt>
            <ImgRow mobile={this.props.mobile}>
              <FeatureBox>
                <img src={Contact} alt="" />
                <p>Put contact information on your cards and add it to all those things you don't want to lose.</p>
              </FeatureBox>
              <FeatureBox>
                <img src={Info} alt="" />
                <p>Register information of your albums, collections or archives to retrieve later on.</p>
              </FeatureBox>
            </ImgRow>

            <ImgRow mobile={this.props.mobile}>
              <FeatureBox>
                <img src={Search} alt="" />
                <p>Find what you are looking for among the things you stored in last spring clean.</p>
              </FeatureBox>
              <FeatureBox>
                <img src={Safe} alt="" />
                <p>Keep your privacy safe through private and public cards, you decide what to show and hide.</p>
              </FeatureBox>
            </ImgRow>
          </ImageContainer>
        </BgCyan>
        <TransitionTwo>
          <Transition invert bottom />
        </TransitionTwo>
        <BgWhite>
          <ImageContainer mobile={this.props.mobile}>
            <HomeSubtitle />
            <img src={imgProcess} alt="process" />
          </ImageContainer>
        </BgWhite>
      </Wrapper >
    )
  }
};

export default withRouter(Home);