import styled from "styled-components";

import StyleHl from "../Utils/StyleHelper";

export default styled.button`
  font-family: "Teko", sans-serif;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${StyleHl.colors.second};
  min-width: 120px;
  height: 40px;
  font-size: 1.7rem;
  border: 2px solid ${StyleHl.colors.second};
  border-radius: 3px;
  text-decoration: none;
  padding-top: 5px;

  :focus {
    outline: none;
    box-shadow: 0px 0px 5px #51cec5;
  }

  :hover {
    background: ${StyleHl.colors.second};
    color: white;
    border: 2px solid ${StyleHl.colors.second};
    cursor: pointer;
  }

  @media (max-width: 1024px) {
    width: auto;
    font-size: 1.8em;
    line-height: 1.5em;
  }
`;
