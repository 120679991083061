import React from "react";
import styled from "styled-components";
import StyleHl from "../Utils/StyleHelper";

const Wrap = styled.div`
  background-color: ${(props) => (props.filled ? "transparent" : "white")};
  height: 30px;
  width: 30px;
  margin: auto;
  margin-top: 5px;
`;

const SvgImg = styled.svg`
  fill: ${(props) => (props.filled ? "#ffffff" : "#a231cf")};
`;

export default ({ filled }) => (
  <Wrap title="Print QR" filled={filled}>
    <SvgImg
      filled={filled}
      data-name="Capa 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
    >
      <g>
        <path class="st0" d="M8,3.8H5v3h3V3.8z M7.3,6.1H5.7V4.4h1.7V6.1z" />
        <path
          class="st0"
          d="M14.9,3.8h-3v3h3V3.8z M14.2,6.1h-1.7V4.4h1.7V6.1z"
        />
        <rect x="8.9" y="4.9" class="st0" width="0.8" height="0.8" />
        <polygon
          class="st0"
          points="9.7,6.4 10.5,6.4 11.2,6.4 11.2,5.6 11.2,4.9 10.5,4.9 10.5,5.6 9.7,5.6 9.7,6.4 8.9,6.4 8.9,7.2 9.7,7.2"
        />
        <path
          class="st0"
          d="M19.6,8h-2.1V1.5H2.4V8h-2v7.9h2v2.6h15.1v-2.6h2.1V8z M3.7,2.8h12.5V8h-1.3V7.2h-0.8V8h-1.4V7.2h-0.8h-0.8V8
		H8.9V7.2H8.1V8H5.8V7.2H5V8H3.7V2.8z M16.2,17.2H3.7v-1.3h12.5V17.2z M18.4,10.3h-1.3V9.1h1.3V10.3z"
        />
      </g>
    </SvgImg>
  </Wrap>
);
