import React from "react";
import styled from "styled-components";
import StyleHl from "../Utils/StyleHelper";

const Wrap = styled.div`
  width: 50px;
  height: 40px;
  display: ${(props) => (props.hidden ? "none;" : "flex;")};
  justify-content: center;
  align-items: center;
  border: 2px solid ${StyleHl.colors.first};
  border-radius: 3px;
  background: ${(props) => (props.filled ? StyleHl.colors.first : "white")};
  color: ${(props) => (props.filled ? "white" : StyleHl.colors.first)};

  :focus {
    outline: none;
    box-shadow: 0px 0px 5px #be7dd8;
  }

  :hover {
    background: ${(props) =>
      props.filled ? StyleHl.colors.hover.violet : "white"};
    color: ${(props) => (props.filled ? "white" : StyleHl.colors.hover.violet)};
    border: 2px solid ${StyleHl.colors.hover.violet};
    cursor: pointer;
  }
`;

const SvgImg = styled.svg`
  height: 28px;
  fill: #ffffff;
`;

export default ({ filled, onClick, hidden }) => (
  <Wrap filled={filled} onClick={onClick} hidden={hidden}>
    <SvgImg
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      x="0px"
      y="0px"
    >
      <title>printIcon</title>
      <polygon
        class="st0"
        points="9.7,19.2 5.4,19.2 0.3,11 4.5,8.3 7.6,13.2 15.5,0.8 19.7,3.5 "
      />
    </SvgImg>
  </Wrap>
);
