import React, { Fragment } from "react";
import Cropper from "react-easy-crop";
import styled from "styled-components";
import Colors from "../../Utils/StyleHelper";

import CroppedImage from "../../Utils/CropImage";
import Button from "../../Components/secondBtn";
import ImgDefault from "../../Img/imageIcon.svg";
import CancelBtn from "../../Components/cancel";
import AcceptBtn from "../../Components/accept";

const Wrapper = styled.label`
  height: auto;
  margin-bottom: 20px;
  display: flex;
  flex-flow: wrap;
`;

const BtnRow = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-evenly;

  @media (max-width: 1024px) {
    width: 100%;
    padding: 0;
    justify-content: space-evenly;
  }
`;

const InputImg = styled.input`
  display: none;
`;

const CropContainer = styled.div`
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

const Container = styled.div`
  background-image: url(${ImgDefault});
  position: relative;
  text-align: center;
  height: 200px;
  width: 200px;
  border: 2px ${Colors.colors.second} solid;
  border-radius: 3px;
  margin: auto;

  :hover {
    cursor: pointer;
  }
`;

class ImgUpload extends React.Component {
  constructor() {
    super();
    this.defaultState = {
      imageSrc: null,
      crop: { x: 0, y: 0 },
      zoom: 2,
      aspect: 1 / 1,
      croppedAreaPixels: null,
      croppedImage: null,
      showControls: false,
      disabledImg: false,
    };

    this.state = this.defaultState;
  }

  cancelImage = () => {
    this.setState(this.defaultState);
    document.getElementById("imageUp").value = null;
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({ croppedAreaPixels });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  onFileChange = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const imageDataUrl = await readFile(e.target.files[0]);
      this.setState({
        imageSrc: imageDataUrl,
        crop: { x: 0, y: 0 },
        showControls: true,
      });
    }
  };

  getCroppedImage = async () => {
    const croppedEncoded = await CroppedImage(
      this.state.imageSrc,
      this.state.croppedAreaPixels
    );

    this.setState(
      {
        crop: { x: 0, y: 0 },
        zoom: 1,
        imageSrc: croppedEncoded,
        encodedImage: croppedEncoded,
        showControls: false,
        disabledImg: true,
      },
      () => this.props.onFileFinish(this.state.encodedImage)
    );
  };

  render() {
    return (
      <>
        <Wrapper htmlFor="imageUp">
          <Container>
            <InputImg
              id="imageUp"
              type="file"
              onChange={this.onFileChange}
              disabled={this.state.disabledImg}
            />
            {this.state.imageSrc && (
              <Fragment>
                <CropContainer>
                  <Cropper
                    image={this.state.imageSrc}
                    crop={this.state.crop}
                    zoom={this.state.zoom}
                    aspect={this.state.aspect}
                    onCropChange={this.onCropChange}
                    showGrid={false}
                    onCropComplete={this.onCropComplete}
                    onZoomChange={this.onZoomChange}
                    cropSize={{ width: 200, height: 200 }}
                    minZoom={1}
                    maxZoom={5}
                  />
                </CropContainer>
              </Fragment>
            )}
          </Container>
        </Wrapper>
        <BtnRow>
          <CancelBtn
            hidden={!this.state.showControls}
            onClick={this.cancelImage}
          >
            CANCEL
          </CancelBtn>
          <AcceptBtn
            filled="true"
            hidden={!this.state.showControls}
            onClick={this.getCroppedImage}
          >
            ACCEPT
          </AcceptBtn>
        </BtnRow>
      </>
    );
  }
}

const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => resolve(reader.result), false);
    reader.readAsDataURL(file);
  });
};

export default ImgUpload;
